import * as React from "react"
import Layout from "../components/Layout"

function Shop() {
    if (typeof document !== `undefined`) {
        let typeformScript = document.createElement("script");
        typeformScript.type = "text/javascript";
        typeformScript.src = "//embed.typeform.com/next/embed.js";
        document.body.appendChild(typeformScript)
      }
  return (
    <Layout>
        <div className="container section-header">
            <div data-tf-live="01J0H8CTSXM78Q5XMN0MD07YRA"></div>
        </div>
    </Layout>
  );
}

export default Shop;